import React, { useState, useCallback } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Link } from "gatsby"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import Seo from "../components/seo"
import ToTop from "../components/common/ToTop"

const schema = yup
  .object({
    startupName: yup.string().required("Campo obligatorio"),
    foundersName: yup.string().required("Campo obligatorio"),
    teamNumber: yup.string().required("Campo obligatorio"),
    techTeamNumber: yup.string().required("Campo obligatorio"),
    videoUrl: yup.string().url("URL inválido"),
    startupUrl: yup.string().url("URL inválido").required("Campo obligatorio"),
    email: yup.string().email("Email inválido").required("Campo obligatorio"),
    name: yup.string().required("Campo obligatorio"),
    businessArea: yup.string().required("Campo obligatorio"),
    creationYear: yup
      .number("Debe ser un número")
      .positive("Debe ser mayor un número positivo")
      .min(1978, "Debe ser mayor a 1978")
      .max(
        new Date().getFullYear(),
        "Debe ser máximo " + new Date().getFullYear()
      )
      .required("Campo obligatorio"),
    moneyStage: yup.string().required("Campo obligatorio"),
    countries: yup.string().required("Campo obligatorio"),
    message: yup.string().required("Campo obligatorio"),
    terms: yup
      .boolean()
      .oneOf([true], "Debe aceptar los términos y condiciones"),
  })
  .required()

const ContestPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [formState, setFormState] = useState("idle")
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }
    try {
      const token = await executeRecaptcha("formSubmit")

      const response = await fetch(
        "https://jbq34euk5wxov724vl2turezxy0qscmh.lambda-url.us-east-1.on.aws/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        }
      )
      const res = await response.json()

      return res?.data?.score >= 0.5
    } catch (error) {
      setFormState("error")
      return false
    }
  }, [executeRecaptcha])

  const onSubmit = async data => {
    setFormState("loading")
    const recapchaVal = await handleReCaptchaVerify()
    if (!recapchaVal) {
      setFormState("error")
      return
    }

    console.log({ data })
    try {
      const response = await fetch(
        "https://gavjd4ir7xtvgr6ggp32cxqwvm0hrttw.lambda-url.us-east-1.on.aws/",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      )

      const res = await response.json()
      console.log(res.code)
      if (res.code === "CREATE_SUCCESS") {
        setFormState("success")
        reset()
        return
      }

      if (res.code === "ALREADY_EXISTS") {
        setFormState("alreadyExist")
        reset()
        return
      }
      reset()
      setFormState("idle")
    } catch (e) {
      setFormState("error")
    }
  }

  return (
    <div id="home" className="pb-28">
      <Seo
        title="¿Te gustaría ganar una consultoría tecnológica? - SymLab"
        description="Con motivo del tercer aniversario de Symlab hemos abierto una convocatoria para realizar una consultoría tecnológica a una startup latinoamericana, que busque conocer su realidad tecnológica, puntos débiles y potenciar fortalezas."
      />
      <div className="bg-sym-light-gray w-full">
        <div className="relative max-w-7xl mx-auto flex space-x-4 ">
          <div className="px-10 md:py-0 md:absolute w-full md:w-1/2 mt-14">
            <img
              src="/logo/logo-horizontal.svg"
              className="w-[390px] mx-auto"
              alt="SymLab Logo"
              title="SymLab"
            />
            <h1 className="mt-10 text-5xl text-center leading-relaxed text-sym-deep-purple">
              ¿Te gustaría ganar una consultoría tecnológica?
            </h1>
          </div>
          <div className="w-full">
            <img
              src="/header-startup.jpg"
              alt="Pizarra blanca con persona adelante"
            />
          </div>
        </div>
      </div>
      <div className="bg-white py-10 px-6 md:px-0">
        <div className="prose mx-auto prose-xl">
          <h2>¡Bienvenido!</h2>
          <p>
            Con motivo del tercer aniversario de Symlab hemos abierto una
            convocatoria para realizar una consultoría tecnológica a una startup
            latinoamericana, que busque conocer su realidad tecnológica, puntos
            débiles y potenciar fortalezas.
          </p>

          <p>
            Durante el mes de Noviembre, se preseleccionarán a aquellas startups
            que pasen el proceso de valoración. Las preseleccionadas serán
            entrevistadas por el equipo de Symlab.
          </p>

          <p>
            Fecha de la convocatoria: del{" "}
            <strong>25 de Octubre al 25 de Noviembre</strong>.
          </p>

          <p>Requisitos para participar</p>

          <ul>
            <li>Ser una startup con un componente de tecnología importante.</li>
            <li>No debe superar los 2 años de constitución.</li>
            <li>Tener un modelo de negocio definido y claro.</li>
          </ul>

          <h3>
            ¿Por qué es importante para una startup tecnológica contar con
            consultoría en Due Diligence?
          </h3>
          <p>
            <a
              href="https://symlab.io/blog/2022/por-que-es-importante-para-una-startup-tecnologica-contar-con-consultoria-en-due-diligence/"
              target="_blank"
              title="¿Por qué es importante para una startup tecnológica contar con
            consultoría en Due Diligence?"
              rel="noreferrer"
            >
              Te lo contamos aquí
            </a>
          </p>
        </div>
      </div>
      <div>
        <div className="max-w-4xl mx-auto py-10 px-6 md:px-0">
          <h2 className="text-5xl font-bold">Participa aquí</h2>
          <p className="mt-6 text-xl">
            Llena este formulario para conocer más de tu Startup y participar
            para ganar la consultoría.
          </p>
          <form className="mt-16" onSubmit={handleSubmit(onSubmit)}>
            <p className="italic mb-4">* Todos los campos son obligatorios</p>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="startupName"
                >
                  Nombre Startup*
                </label>
                <input
                  id="startupName"
                  className="w-full"
                  type="text"
                  placeholder="Nombre Startup"
                  {...register("startupName", { required: true })}
                />
                <p className="text-red-500">{errors.startupName?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="startupUrl"
                >
                  Página Web*
                </label>
                <input
                  className="w-full"
                  id="startupUrl"
                  type="text"
                  placeholder="Link de tu página web. Por ejemplo: https://symlab.io"
                  {...register("startupUrl", { required: true })}
                />

                <p className="text-red-500">{errors.startupUrl?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="foundersName"
                >
                  Nombre y Apellidos de Fundadores*{" "}
                  <span className="lowercase text-sm">(uno por linea)</span>
                </label>
                <textarea
                  className="h-40 w-full"
                  id="foundersName"
                  type="text"
                  placeholder="Nombre Fundadores"
                  {...register("foundersName", { required: true })}
                />
                <p className="text-red-500">{errors.foundersName?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="teamNumber"
                >
                  Personas del equipo*
                </label>
                <select {...register("teamNumber")}>
                  <option value="1-10">1-10</option>
                  <option value="10-20">10-20</option>
                  <option value="20-50">20-50</option>
                  <option value="50+">más de 50</option>
                </select>
                <p className="text-red-500">{errors.teamNumber?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="techTeamNumber"
                >
                  De esas personas, ¿cuántas están dedicadas al desarrollo de
                  software?*
                </label>
                <select {...register("techTeamNumber")}>
                  <option value="1-5">1-5</option>
                  <option value="5-10">5-10</option>
                  <option value="10-50">10-50</option>
                  <option value="50+">más de 50</option>
                </select>
                <p className="text-red-500">{errors.techTeamNumber?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="videoUrl"
                >
                  Video Presentación (opcional)
                </label>
                <input
                  className="w-full"
                  id="videoUrl"
                  type="text"
                  placeholder="Link video presentación. Por ejemplo: https://youtube.com"
                  {...register("videoUrl", { required: true })}
                />
                <p>
                  Grabar un video de máximo 2 minutos donde se presenten,
                  describan la solución que están desarrollando y el modelo de
                  negocio. El video lo deben subir a YouTube o Vimeo y colocar
                  aquí el link.
                </p>
                <p className="text-red-500">{errors.videoUrl?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="businessArea"
                >
                  Área de negocios*
                </label>
                <input
                  className="w-full"
                  id="businessArea"
                  type="text"
                  placeholder="A donde va dirigida tu startup"
                  {...register("businessArea", { required: true })}
                />
                <p className="text-red-500">{errors.businessArea?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="creationYear"
                >
                  Año de creación*
                </label>
                <input
                  className="w-full"
                  id="creationYear"
                  type="number"
                  placeholder="Por ejemplo 2020"
                  {...register("creationYear", {
                    required: true,
                    min: 1978,
                    max: 2022,
                    validate: {
                      positive: v => parseInt(v) > 0,
                      lessThan: v => parseInt(v) < 2223,
                      moreThan: v => parseInt(v) > 1978,
                    },
                  })}
                />
                <p className="text-red-500">{errors.creationYear?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="countries"
                >
                  País(es) en los que operan*
                </label>
                <input
                  className="w-full"
                  id="countries"
                  type="text"
                  placeholder="Por ejemplo: España, Alemania, Estados Unidos"
                  {...register("countries", { required: true })}
                />
                <p>Separálos por coma</p>
                <p className="text-red-500">{errors.countries?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="moneyStage"
                >
                  ¿En qué etapa del levantamiento de capital están?*
                </label>
                <input
                  className="w-full"
                  id="moneyStage"
                  type="text"
                  placeholder="Por ejemplo: Bootstrap, Seed, Series A, etc."
                  {...register("moneyStage", { required: true })}
                />
                <p className="text-red-500">{errors.moneyStage?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="message"
                >
                  Cuéntanos por qué te gustaría contar con la consultoría*
                </label>
                <textarea
                  className="h-40 w-full"
                  id="message"
                  type="text"
                  placeholder=""
                  {...register("message", { required: true })}
                />
                <p className="text-red-500">{errors.message?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="name"
                >
                  Nombre Contacto*
                </label>
                <input
                  id="name"
                  className="w-full"
                  type="text"
                  placeholder="Tu nombre completo"
                  {...register("name", { required: true })}
                />
                <p className="text-red-500">{errors.name?.message}</p>
              </div>
            </div>

            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                  htmlFor="email"
                >
                  Email Contacto*
                </label>
                <input
                  id="email"
                  className="w-full"
                  type="text"
                  placeholder="Email donde podemos contactarte"
                  {...register("email", { required: true })}
                />
                <p className="text-red-500">{errors.email?.message}</p>
              </div>
            </div>
            <div className="-mx-3 mb-6">
              <div className="w-full px-3">
                <div className="flex space-x-4">
                  <input
                    id="terms"
                    type="checkbox"
                    {...register("terms", { required: true })}
                  />
                  <label htmlFor="terms">
                    Acepto los{" "}
                    <a
                      className="underline"
                      href="https://symlab.io/tyc-consultoria"
                      title="Términos y condiciones del concurso"
                      target="_blank"
                      rel="noreferrer"
                    >
                      términos y condiciones
                    </a>
                  </label>
                  <p className="text-red-500">{errors.terms?.message}</p>
                </div>
              </div>
            </div>

            <p className="italic mb-4">* Todos los campos son obligatorios</p>

            <p className="text-xl">¡Mucha suerte!</p>

            {formState === "error" && (
              <p className="text-red-500 font-bold text-xl mt-4">
                Ocurrió un error enviando el formulario por favor intenta de
                nuevo
              </p>
            )}

            {formState === "alreadyExist" && (
              <p className="text-red-500 font-bold text-xl mt-4">
                Ya aplicaste, si necesitas cambiar algo ponte en contacto con
                nosotros a través de nuestro formulario de{" "}
                <Link className="underline" to="/">
                  contacto.
                </Link>
              </p>
            )}

            <button
              disabled={formState === "loading"}
              className="bg-gradient-to-b from-sym-purple to-sym-light-blue py-4 px-16 mt-10 rounded-full text-xl font-bold mx-auto text-white hover:from-sym-sym-purple hover:to-sym-purple"
              type="submit"
            >
              {formState === "loading" ? "Espera" : "Enviar"}
            </button>

            {formState === "success" && (
              <>
                <p className="text-green-600 font-bold text-xl mt-4">
                  ¡Muchas gracias por participar!
                  <br /> Si tu startup queda dentro de las seleccionadas, te
                  estaremos contactando.
                </p>
                <p className="mt-10 text-xl">
                  Te invitamos a seguir a Symlab en{" "}
                  <a
                    className="underline"
                    href="https://www.linkedin.com/company/somossymlab"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Linkedin
                  </a>
                </p>
              </>
            )}
            <div id="recaptcha-container" />
          </form>
        </div>
      </div>
      <div className="bg-sym-light-gray py-10 px-6 md:px-0">
        <div className="prose mx-auto prose-xl">
          <h3>DINÁMICA</h3>
          <p>
            Desde el martes 25 de Octubre hasta el viernes 25 de Noviembre, las
            startups interesadas en recibir una consultoría tecnológica, deben
            ingresar a <strong>https://symlab.io/consultoria</strong> (esta
            página), y completar la información que se le solicita.
          </p>{" "}
          <p>
            {" "}
            Un equipo de Symlab seleccionará a una startup que considere que se
            adapte mejor para recibir esta consultoría.
          </p>{" "}
          <p>
            La startup ganadora será anunciada la primera quincena de Diciembre.
          </p>{" "}
          <h3>¿QUÉ INCLUYE LA CONSULTORÍA?</h3>
          <p>Un proceso que dura un mes:</p>
          <ul>
            <li>
              <strong>Semana 1</strong>: Envío de formulario con preguntas
            </li>
            <li>
              <strong>Semana 2</strong>: Entrevista con el equipo.
            </li>
            <li>
              <strong>Semana 3</strong>: Estudio del caso – realización del
              informe
            </li>
            <li>
              <strong>Semana 4</strong>: Presentación y entrega del informe
            </li>
          </ul>
          <h3>¿QUÉ INCLUYE EL INFORME?</h3>
          <ol>
            <li>
              Evaluar y detectar los riesgos Se trabajará en preguntas sobre la
              realidad tecnológica de o las startups. Puntos a evaluar del
              equipo tecnológico. · Evaluación del equipo. · Aplicaciones y
              herramientas. · Arquitectura e infraestructura. · Escalabilidad de
              la solución.{" "}
            </li>
            <li>Identificar sinergias y oportunidades.</li>
          </ol>
          <p className="italic font-semibold">
            Estas sesiones se podrán realizar durante el 4Q del 2022, o el 1Q
            del 2023 en una fecha acordada entre ambas partes.
          </p>
        </div>
      </div>
      <div className="mt-10">
        <Link to="/">
          <img
            src="/logo/logo-horizontal.svg"
            className="w-[290px] mx-auto"
            alt="SymLab Logo"
            title="SymLab"
          />
        </Link>
      </div>

      <ToTop />
    </div>
  )
}

export default ContestPage
